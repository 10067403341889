/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 31번째 로그에서는 아웃사이더 님을 게스트로 모시고 Facebook F8 2019, 핀테크 업체의 금융 정보 스크래핑 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.f8.com/"
  }, "Facebook Developer Conference. April 30 - May 1, 2019.San Jose, CA.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://f8seoul2019.splashthat.com/"
  }, "F8 2019 Meetup: Seoul")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/groups/DevCSeoul/"
  }, "Facebook Developer Circle: Seoul")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/help/116067818477568?helpref=faq_content"
  }, "프로필을 Facebook 페이지로 전환하려면? | Facebook 고객 센터")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.facebook.com/blog/post/2019/02/20/f8-2019-registration-now-open/"
  }, "F8 2019 Registration Now Open")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://research.fb.com/downloads/pytorch/"
  }, "PyTorch - Facebook Research")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/cowx2ater/photos/a.156948108323045/318858575465330/"
  }, "뉴비톤 - 우물 128번지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://likelion.net/"
  }, "멋쟁이사자처럼 :: LIKELION")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oculus.com/quest/"
  }, "Oculus Quest | Oculus")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1442"
  }, "토스가 현대카드를 연동하는 방법에 동의하지 않습니다 :: Outsider's Dev Story")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/ohyecloudy/status/1128894471419170816"
  }, "트위터의 오종빈(Jongbin Oh) 님: \"#뱅크샐러드 반응 좋다. 어쩐지 연동하니 비번 변경됐다는 문자가 오더라니....\" / 트위터")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hyundaicard.com/index.jsp"
  }, "현대카드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/economy/view/2013/07/607452/"
  }, "현대카드, 비(非)공인인증 논란 속 ", React.createElement(_components.code, null, "보안"), " 고집하는 이유는 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://whooing.com/"
  }, "후잉가계부(whooing)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.mtn.co.kr/newscenter/news_viewer.mtn?gidx=2019022111350252625"
  }, "금융위 \"오픈 API 고도화 추진...스크래핑은 금지\" - 머니투데이방송")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://banksalad.com/"
  }, "신경꺼도 내 돈 관리 ㅣ 뱅크샐러드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://digital-forensics.sans.org/blog/2016/02/23/iphone-forensics-separating-the-facts-from-fiction-a-technical-autopsy-of-the-apple-fbi-debate"
  }, "SANS Digital Forensics and Incident Response Blog | A Technical Autopsy of the Apple - FBI Debate using iPhone forensics | SANS Institute")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mint.com/"
  }, "Budget Tracker & Planner | Free Online Money Management | Mint")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.intuit.com/"
  }, "Intuit®: Official Site | Powering Financial Prosperity‎")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://campaign.naver.com/ncard/"
  }, "오프라인 결제의 시작 : 네이버페이 체크카드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://campaign.naver.com/npay/zeropay_preopen/"
  }, "제로페이 프로모션 : 네이버페이")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://1password.com/"
  }, "Password Manager for Families, Businesses, Teams | 1Password")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://1password.com/teams/white-paper"
  }, "The 1Password for Teams security design")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://xkcd.com/538/"
  }, "xkcd: Security")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tools.ietf.org/html/rfc6749"
  }, "RFC 6749 - The OAuth 2.0 Authorization Framework")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/outsideris/blog-comments/issues/44#issuecomment-493667006"
  }, "토스 공식 입장 - /1442 · Issue #44 · outsideris/blog-comments")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kmib.co.kr/article/view.asp?arcid=0013329544&code=61121211&cp=nv"
  }, "금감원에 우리은행 민원 제기했다가 ‘신용불량자’된 사연-국민일보")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
